.root {
  button {
    font-weight: 700 !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px !important;
  }
}

.cancelAttendanceButton {
  font-weight: 700 !important;
  align-items: center !important;
  width: 100%;
}
.cancelBtn {
  width: 123px;
  height: 40px;
  background-color: #414142 !important;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 560px) {
  .cancelBtn {
    width: 100% !important;
  }
}
