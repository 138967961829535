.grid {
  display: grid;
  column-gap: 10px;
}

.header {
  position: relative;
  padding: 11px 0;
  text-align: center;
  width: 100%;
  h4 {
    margin: 5px 0;
    text-align: center;
  }
}

.arrowLeft {
  cursor: pointer;
  position: absolute;
  left: 0;
  margin-top: -15px;
}

.arrowRight {
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-top: -15px;
}

.singleDay {
  width: 100%;
  border-top: 2px solid #d7d7d7;
}
.singleDayHeader {
  padding: 11px 0;
  text-align: center;
  margin: 0;
  border-bottom: 2px solid #d7d7d7;
}

@media screen and (min-width: 768px) {
  .singleDay {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
