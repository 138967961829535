.tileContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.tile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 40px;
  width: 136px;
  height: 136px;
  margin-bottom: 5px;
  @apply border-primary border-opacity-30 bg-primary bg-opacity-30;
}

.tileIcon {
  max-width: 90%;
}

.title {
  font-size: 17px;
}

.text {
  font-weight: bolder;
  font-size: 30px;
  text-align: center;
}

@media (min-width: 768px) {
  .tile {
    width: 180px;
    height: 180px;
  }

  .title {
    font-size: 18px;
  }
  .text {
    font-size: 25px !important;
  }
}

@media (min-width: 1024px) {
  .tileContainer {
    .text {
      font-size: 25px !important;
    }
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 85%;
  }
  .text {
    font-size: 25px !important;
  }
}
