.root {
  button {
    font-weight: 700 !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
}

.historyActionContainer {
  display: block;
  * {
    padding-left: 0 !important;
    margin: auto 0 auto auto !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.warningClassInfo {
  color: red !important;
  margin: 0px !important;
  font-weight: bold !important;
  padding: 5px 10px 0 0;
}

.adminActionButtonContainer {
  min-height: 39px;
  word-wrap: break-spaces;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center !important;
  * {
    justify-content: space-between;
    margin: auto 0 auto auto;
    text-align: right;
  }
}

.alreadyTakenPlace {
  font-size: 0.6rem;
  text-align: center;
  font-weight: bold;
}

@media screen and (max-width: 760px) {
  .adminActionButtonContainer {
    flex-wrap: wrap;
  }
}
