.date {
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  :first-child {
    margin-right: 10px;
  }

  .dayName {
    font-weight: normal;
    float: right;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.eventContainer {
  .event {
    // background: $brand-color;
    color: #fff;
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;

    p {
      margin: 0;
      font-size: 10px;
    }

    h5 {
      margin: 0;
    }
  }
}
