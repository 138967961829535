.my-form {
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-item-label > label {
    font-size: 1.125rem;
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }

  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none; /* hide required star next to label*/
  }
  .ant-form-item-explain-error {
    font-size: 0.85rem;
  }
}

.my-modal {
  .ant-modal-body {
    margin-top: 24px;
    font-size: 1.2rem;
  }
  .ant-modal-footer {
    display: none;
  }
}

.my-checkbox {
  font-size: 18px !important;

  .ant-checkbox > span {
    width: 26px;
    height: 26px;
  }

  .ant-checkbox-inner::after {
    top: 47%;
    left: 27%;
  }
}
.my-button {
  @apply bg-primary border-primary;
}
