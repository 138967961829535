@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl font-bold my-6;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-bold;
  }
  h4 {
    @apply text-lg font-bold my-2;
  }
  h5 {
    @apply font-bold;
  }

  a:hover {
    @apply no-underline text-primary;
  }
}

@layer components {
  .body-width {
    padding: 0 5%;
    margin: 20px auto;
    max-width: 1023px;
  }
  .body-container {
    padding: 0 5%;
    margin: 20px auto;
    max-width: 1023px;
    min-height: calc(100vh - 80px - 110px - 40px);
  }
  .homepage_header {
    margin-top: -110px;
  }
  .homepage_hero {
    @apply z-0 object-cover h-[700px] w-screen;
  }
  .homepage_body_container {
    max-width: 1023px;
    margin: 0 auto;
  }
  .homepage_footer {
    margin-bottom: 80px;
  }

  .modalButtonsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 10px;
    justify-content: space-around;
    margin-top: 30px;

    @media screen and (max-width: 639px) {
      grid-template-columns: 1fr;
    }
  }
  .modalButtonsContainer button {
    width: 100% !important;
    height: 100%;
  }

  .tableHeader {
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 5px;
    align-items: flex-end;
    align-content: flex-end;
    @apply border-b-2 border-secondary;
    @apply text-secondary align-bottom items-end break-normal;
  }
  .tableItem {
    margin: 6px 0;
  }

  .gridAutoCols {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 10px;
    justify-content: space-around;
    align-items: center;
  }

  .filterContainer {
    @apply w-full p-4 bg-neutral-100 rounded-md mb-4;
  }
  .filterLabel {
    @apply font-bold text-sm;
  }
  .titleAndBtnContainer {
    @apply md:flex justify-between;
  }
  .titleAndBtnContainer button {
    @apply w-full md:w-auto md:mt-2;
  }

  .listItem {
    border-bottom: 1px solid;
    @apply p-2 sm:p-6;
    @apply border-neutral-200 hover:bg-neutral-100;
  }
  .listItem:last-child {
    border: none;
  }
  .formErrorMsg {
    font-size: 13.6px;
    @apply text-custom-red;
  }

  .formLabel {
    font-size: 1.125rem;
  }

  .oList {
    @apply list-inside list-decimal ml-8;
    li {
      @apply my-2;
    }
  }
  .oList_a {
    list-style-type: lower-alpha;
    @apply list-inside ml-8;
    li {
      @apply my-2;
    }
  }
  .uList {
    @apply list-inside list-disc ml-8;
    li {
      @apply my-2;
    }
  }
  .sectionTitle {
    @apply text-xl font-bold mt-8 mb-4;
  }
  .sectionSubTitle {
    @apply text-lg font-bold mt-4 mb-4;
  }

  ul.no-bullets {
    list-style-type: none;
    padding-left: 2em;
  }
}
