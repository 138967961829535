.root {
  width: 100%;
  display: block;
}
.grid {
  display: grid;
  grid-template-columns: 200px repeat(7, 1fr);
  border-right: 1px solid #eee;
}

.header {
  font-weight: bold;
  text-align: center;
  border-top: 1px solid #eee;

  padding: 8px 0;
  height: auto !important;
}

.item {
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  height: 48px;
  align-items: center;
  svg {
    margin: 0 4px;
  }
}

.trainerContainer {
  display: flex;
}

.monthLabels {
  font-size: 12px;
  padding: 0 !important;
}

.eventCell {
  vertical-align: top;
}

.event {
  width: 100%;
  height: 6px;
  margin-top: 0 !important;
  margin-bottom: 2px !important;
  background: #20c7c5;
  border-radius: 4px;
  cursor: pointer;
}

.bethInterview {
  background: #f8f8f8;
}

.xsOnly {
  display: none;
}

@media only screen and (max-width: 600px) {
  .xsHidden {
    display: none;
  }
  .xsOnly {
    display: block;
  }
  .grid {
    grid-template-columns: 100px repeat(7, 1fr);
    font-size: 0.7rem;
  }
}
