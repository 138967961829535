.tilesContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 40px auto;
  max-width: 880px;

  @media screen and (max-width: 1000px) {
    max-width: 670px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 768px) {
    max-width: 500px;
  }

  @media screen and (max-width: 570px) {
    max-width: 380px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 370px) {
    display: block;
  }
}
