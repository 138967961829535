.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 110px 1fr;
  grid-gap: 0 20px;
  align-items: center;
}

.subheader {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    display: none;
  }
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }
  .singleClient {
    padding: 20px;
    border-radius: 10px;
    margin: 10px 0;
  }

  .name {
    font-weight: bold;
    font-size: 18px;
  }
  .subheader {
    display: block;
    margin-right: 5px;
    font-size: 10px;
  }
}
